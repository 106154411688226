<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner">
      <b-col sm="12" md="6" lg="3">
        <b-img src="@/assets/images/logo.svg" />

        <h4 class="mt-5">{{ $t("forms.forgotPassword") }}</h4>

        <b-form class="auth-login-form" @submit.prevent="login">
          <b-form-group>
            <label for="login-email">
              {{ $t("forms.email") }} <span class="required">*</span>
            </label>
            <b-form-input
              id="login-email"
              v-model="userEmail"
              name="login-email"
            />
          </b-form-group>

          <div class="login-actions">
            <b-button type="submit" variant="success" block>
              {{ $t("forms.forgotInstructions") }}
            </b-button>
            <router-link class="forgot-password" to="/login">{{ $t("forms.login") }}</router-link>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      userEmail: '',
    }
  },
  methods: {
    sendEmail() {

    },
  },
}
</script>
